.video-content {
  border-radius: 2rem;
  overflow: hidden;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--color-black);
  }
  &.active {
    video {
      opacity: 1;
    }
    .player {
      opacity: 0;
      visibility: hidden;
    }
  }
  video {
    opacity: .7;
    transition: .5s opacity;
  }
  .player {
    transition-duration: .15s;
    transition-property: opacity, visibility;
    img {
      width: 4rem;
      height: auto;
    }
  }
}