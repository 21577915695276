  .forms {
    .bg:not(.bg-texture) {
      width: 100%;
    }
  }

  @media (width >=768px) {
    #register {
      .txt-top {
        max-width: 24.688rem;
        margin: auto;
      }

      form {
        column-gap: 6.188rem;

        .content-input {
          flex: 1 calc(50% - 6.188rem);

          .input-group-checkbox {
            max-width: 23.938rem;
          }
        }
      }

      .bg:not(.bg-texture) {
        width: 50%;
        bottom: -0.3rem;
      }
    }
  }