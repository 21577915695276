#header {
  position: fixed;
  top: 0;
  z-index: 5;
  nav {
    border-radius: 1.333rem;
    background-color: var(--color-blue);
  }
  .logo {
    width: clamp(11.5rem, 45%, 12rem);
  }
}

#burger {
  --move: 5px;
  width: 35px;
  height: 35px;
  border-radius: 12px;
  cursor: pointer;
  transition: .25s border-radius;
  background-color: var(--color-red);
  span, &::before, &::after {
    content: '';
    width: 17px;
    height: 2px;
    border-radius: 2px;
    margin: auto;
    position: absolute;
    inset: 0;
    transition: .25s transform;
    background-color: var(--color-white);
  }
  &::before {
    transform: translateY(calc(var(--move) * -1)) rotate(0deg);
  }
  &::after {
    transform: translateY(var(--move)) rotate(0deg);
  }
  @media (hover: hover) {
    &:hover {
      border-radius: 8px;
      span {
        transform: scaleX(.9);
      }
      &::before {
        transform: translateY(calc(var(--move) * -1.2)) scaleX(1.15) rotate(0deg);
      }
      &::after {
        transform: translateY(calc(var(--move) * 1.2)) scaleX(1.15) rotate(0deg);
      }
    }
  }
  &.active {
    span {
      transform: scaleX(0);
    }
    &::before {
      transform: translateY(0) rotate(135deg);
    }
    &::after {
      transform: translateY(0) rotate(45deg);
    }
  }
}

#menu {
  --anim-delay: .05s;
  width: 100%;
  height: 100dvh;
  max-width: 64rem;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4;
  opacity: 0;
  visibility: hidden;
  transform: scale(.95);
  transition-duration: .15s;
  transition-property: visibility, opacity, transform, height;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition-duration: .35s, .35s, .2s, .1s;
    ul li {
      opacity: 2;
      transform: translateX(0);
      transition-duration: .2s, .3s;
      &:nth-child(1) {
        transition-delay: calc((var(--anim-delay) * 1) + .15s);
      }
      &:nth-child(2) {
        transition-delay: calc((var(--anim-delay) * 2) + .15s);
      }
      &:nth-child(3) {
        transition-delay: calc((var(--anim-delay) * 3) + .15s);
      }
      &:nth-child(4) {
        transition-delay: calc((var(--anim-delay) * 4) + .15s);
      }
      &:nth-child(5) {
        transition-delay: calc((var(--anim-delay) * 5) + .15s);
      }
      &:nth-child(6) {
        transition-delay: calc((var(--anim-delay) * 6) + .15s);
      }
      &:nth-child(7) {
        transition-delay: calc((var(--anim-delay) * 7) + .15s);
      }
      &:nth-child(8) {
        transition-delay: calc((var(--anim-delay) * 8) + .15s);
      }
    }
  }
  .bg {
    position: fixed;
    img {
      object-position: top center;
    }
  }
  .content-scroll {
    max-height: 100%;
    padding-top: calc(12rem - 5%);
    overflow-y: auto;
    overflow-x: hidden;
  }
  ul {
    max-width: max-content;
    margin-left: min(calc(25% - 5rem), 7.5rem);
    li {
      list-style: none;
      opacity: 0;
      transform: translateX(-50vw);
      transition: 0s transform .2s, 0s opacity .2s;
    }
  }
  .link {
    &.active {
      --color: var(--color-red);
      img[src*='mark'] {
        opacity: 1;
      }
    }
    img[src*='mark'] {
      width: 2rem;
      height: auto;
      opacity: 0;
    }
  }
}