.forms {
  .divisor {
    padding: 0 3rem;

    &::before,
    &::after {
      content: '';
      width: auto;
      height: 1px;
      flex: 1 auto;
      background-color: var(--color-blue);
    }
  }

  .btns-special {
    box-shadow: var(--shadow);
    --color: rgba(30, 45, 77, 0.87);
    border: 1px solid rgba(6, 30, 71, 0.30);
    background-color: var(--color-white);
  }

  .bg {
    bottom: -10.65rem;
  }
}

@media (width >=551px) {
  .forms {
    .bg:not(.bg-texture) {
      bottom: -3.3rem;
      right: 0;
      left: inherit;
      width: 100%;
    }

    .bg-texture {
      bottom: -17.65rem;
      background-color: var(--color-gray);
    }

    .img-logo-checo {
      max-width: 30rem;
      margin-top: -11rem;
    }

    .img-blur {
      width: 100%;
      max-width: 25rem;
    }

    .cards-content {
      .img-card {
        width: 11rem;
        &.card-one {
          transform: translate(64px, 76px);
          z-index: 1;
        }

        &.card-two {
          transform: translate(10px, 11px);
        }

        &.card-three {
          transform: translate(-94px, 129px);
          z-index: 1;
        }
      }
    }
  }
}