#home {
  .banner {
    overflow: hidden;
    .logo-checo {
      width: 80%;
      max-width: 25rem;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
  }

  .info {
    row-gap: 2.5rem;
    h2 {
      font-size: 1.65rem;
    }
    .description-card {
      min-height: 100%;
      border-radius: 2rem 2rem 0 0;
      box-shadow: 0px .333rem .333rem rgba(0, 0, 0, 0.25);
      margin-top: -2rem;
      background-color: var(--color-white);
    }

    .text-user {
      text-transform: uppercase;
      font-weight: 600;
    }

  }
  @media screen and (min-width: 769px) {
    .banner {
      .logo-checo {
        width: 75%;
        max-width: 41.85rem;
        margin-bottom: 3.5rem;
      }
    }
    .info {
      .description-card {
        margin-top: 0;
        .description-card-content {
          max-width: 24rem;
        }
      }
      .home-tutorial .bg-tutorial {
        display: none;
      }
    }
  }
}

