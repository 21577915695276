#game {
  height: 100svh;
  background-color: var(--color-black);
  .card-content {
    .h1 {
      text-shadow: 0 0 2px black;
      bottom: 7.5rem;
      z-index: 1;
      pointer-events: none;
    }
    .show-animation {
      transition-duration: .2s;
      transition-property: opacity, visibility;
      &.save {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  .bg {
    object-position: bottom center;
  }
  .btns.save {
    max-width: 85%;
    bottom: 2rem;
    z-index: 1;
  }
  + footer {
    display: none;
  }
}

.errors {
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition-duration: .25s;
  transition-property: opacity, visibility;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    opacity: 0.9;
    background: var(--color-blue);
  }
  img {
    width: 8.333rem;
  }
  p {
    font-size: 1.5rem;
  }
  .description {
    max-width: 21.5rem;
    margin: 7.75rem auto 6rem;
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.show-card:has(#game) .card-content .show-animation {
  &.h1 {
    opacity: 0;
    visibility: hidden;
  }
  &.save {
    opacity: 1;
    visibility: visible;
    transition-delay: 1s;
  }
}
