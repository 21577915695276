#footer {
  border-radius: 2rem 2rem 0 0;
  margin-top: -1.75rem;
  background-color: var(--color-black);
  .footer-content {
    max-width: 63.5rem;
    column-gap: 1rem;
    .text-center.flex {
      column-gap: 1rem;
    }
  }
  .copyright {
    max-width: 16.666rem;
  }
  .logo {
    width: 10.7rem;
  }
  .rrss {
    column-gap: .8333rem;
    a {
      width: 3.75rem;
      display: block;
    }
  }
  p {
    font-size: 10px;
  }
  .links p {
    white-space: nowrap;
  }

  @media screen and (min-width: 769px) {
    .logo {
      width: 8.333rem;
    }
    .rrss {
      column-gap: .555rem;
      a {
        width: 2.5rem;
      }
    }
  }
}
