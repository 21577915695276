#album {
  .bg {
    bottom: 0;
  }
  .content-grid {
    max-width: 80rem;
    .card:nth-child(even) {
      transform: translateY(1.5rem);
    }
    .box {
      aspect-ratio: 280 / 420;
      &.inactive, img {
        box-shadow: 0px .333rem 1rem rgba(0, 0, 0, 0.25);
      }
      &.inactive {
        background-color: var(--color-blue);
      }
      &.inactive::before, &:not(.inactive)::after {
        content: attr(data-index);
        font-weight: 600;
      }
      &::before {
        width: max-content;
        height: max-content;
        margin: auto;
        position: absolute;
        inset: 0;
        font-size: 3rem;
        --color: var(--color-white);
      }
      &::after {
        margin-top: .8333rem;
        display: block;
        --color: var(--color-blue);
        font-size: 1.166rem;
        text-align: center;
      } 
      a {
        display: block;
      }
    }
  }
  .paginator button {
    width: 2rem;
    cursor: pointer;
    &:last-child img {
      transform: rotate(180deg);
    }
  }
}
