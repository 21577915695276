#news {
  .bg {
    bottom: -8.85%;
  }
  .note {
    box-shadow: var(--shadow);
    border-radius: 1.25rem;
    display: block;
    overflow: hidden;
    .title {
      bottom: 0;
      &::before {
        content: '';
        position: absolute;
        inset: 0;
        filter: blur(1.25rem);
        mix-blend-mode: multiply;
        background: var(--color-blue);
        opacity: 0.3;
      }
    }
  }
}

@media (width > 550px) {
  #news .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    .new {
      padding: 0;
      margin-bottom: 0;
    }
  }
}

@media (550px < width <= 768px) {
  #news .grid-container .new .h2 {
    font-size: 1rem;
  }
}

@media (width > 768px) {
  #news .grid-container {
    max-width: 56.111rem;
    grid-template-columns: repeat(3, 1fr);
    .new {
      &:nth-child(6n + 1), &:nth-child(6n - 1) {
        height: max-content;
        grid-column: span 2;
        grid-row: span 2;
      }
      &:not(:nth-child(6n + 1)):not(:nth-child(6n - 1)) .h2 {
        font-size: .888rem;
      }
    }
  }
}