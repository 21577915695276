#detail-card {
  .back {
    width: 3rem;
    display: block;
    position: absolute;
    top: 6rem;
    left: 5%;
    z-index: 2;
  }
  .content-card {
    max-width: min(23.333rem, 80%);
    aspect-ratio: 640 / 960;
  }
  .loader-img {
    aspect-ratio: 273 / 410;
  }
  .tooltip {
    max-width: max-content;
    max-height: max-content;
    border-radius: .5rem;
    bottom: calc(100% - 1.5rem);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    transition-duration: .65s;
    transition-property: opacity, visibility;
    background-color: var(--color-white);
    &.active {
      opacity: 1;
      visibility: visible;
      transition-duration: .2s;
    }
    .close-tooltip {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      top: 0;
      right: 0;
      &::before, &::after {
        content: '';
        width: .75rem;
        height: 1px;
        margin: auto;
        position: absolute;
        inset: 0;
        transform: rotate(45deg);
        background-color: var(--color-blue);
      }
      &::after {
        transform: rotate(-45deg);
      }
      span {
        width: 1.25rem;
        height: 1.25rem;
        border: 1px solid var(--color-blue);
        border-radius: 50%;
        display: block;
      }
    }
  }
  .info {
    max-width: 30rem;
  }
  .btns {
    max-width: 30rem;
    .locked {
      opacity: .5;
    }
  }

  @media (width >= 769px) {
    min-height: calc(100vh - 12.5rem);
    h1 {
      max-height: max-content;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: max(7.5%, calc(50% - 35rem));
      font-size: 4rem;
      transform: translateX(-50%) rotate(-90deg);
    }
    .content-card {
      margin-left: 5rem;
    }
    .home-tutorial {
      padding-top: 5rem;
      .video-content {
        max-width: 30rem;
        margin: auto;
      }
    } 
  }
}

.card-interactive {
  --card-scale: 1;
  --translate-x: 0px;
  --translate-y: 0px;
  --rotate-x: 0deg;
  --rotate-y: 0deg;
  
  pointer-events: none;
  transform-style: preserve-3d;
  transform: translate3d(0px, 0px, 0.01px);

  .card-translate, .card-rotator {
    perspective: 600px;
    transform-origin: center;
    transition: .65s transform ease-out;
    will-change: transform;
  }
  .card-translate {
    transition-timing-function: ease-in-out;
    transform: translate3d(var(--translate-x), var(--translate-y), 0.1px) scale(var(--card-scale));
  }
  .card-rotator {
    box-shadow: 0px .333rem 1rem 0px rgba(0, 0, 0, 0.25);
    display: grid;
    transform-style: preserve-3d;
    transform: rotateY(var(--rotate-x)) rotateX(var(--rotate-y));
    pointer-events: auto;
    background: hsl(234, 41%, 15%);
    > * {
      grid-area: 1 / 1;
      overflow: hidden;
      transform-style: preserve-3d;
    }
  }
  img {
    transform-style: preserve-3d;
    will-change: transform;
    image-rendering: optimizeQuality;
    background: hsl(234, 41%, 15%);
  }
  .card-back {
    transform: translate3d(0px, 0px, 0px);
    backface-visibility: visible;
    img {
      transform: rotateY(180deg);
    }
  }
  .card-front {
    backface-visibility: hidden;
  }

  &.active {
    --card-scale: 1.25;
    --rotate-x: 360deg;
    .card-translate, .card-rotator {
      transition-duration: .6s;
    }
  }
}