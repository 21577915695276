@font-face {
  font-display: swap;
  font-family: 'Venera';
  src: url('./fonts/venera-900.woff2') format('woff2'),
    url('./fonts/venera-900.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Bull';
  src: url('./fonts/Bull-Regular.woff2') format('woff2'),
    url('./fonts/Bull-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Bull';
  src: url('./fonts/Bull-Medium.woff2') format('woff2'),
    url('./fonts/Bull-Medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-family: 'Bull';
  src: url('./fonts/Bull-Heavy1.woff2') format('woff2'),
    url('./fonts/Bull-Heavy1.ttf') format('truetype');
  font-weight: 600;
}

a {
  text-decoration: none;
}

p, h1, h2, h3, h4, h5,
label, small, a,
input, textarea, select, button {
  line-height: normal;
  font-weight: normal;
}
label {
  font-size: 1.27rem;
}
p { // 12px
  font-size: 1rem;
  line-height: 1.47em;
}
small { // 10px
  font-size: .833rem;
}
h1, .h1 { // 24px
  font-size: 2rem;
}
h2, .h2 { // 16px
  font-size: 1.333rem;
}
h3, .h3 { // 14px
  font-size: 1.166rem;
}
.b { // bold
  font-weight: 500;
}
.text-white {
  --color: var(--color-white)
}
.text-red {
  --color: var(--color-red)
}
.text-blue {
  --color: var(--color-light-blue)
}
.text-center {
  text-align: center;
}
.text-venera {
  --typography: 'Venera';
}

@media screen and (min-width: 769px) {
  .text-md-left {
    text-align: left;
  }
}