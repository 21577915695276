.btns .skeleton {
  position: absolute;
  inset: 0;
}
.skeleton {
  display: block;
  animation: shine 1.5s infinite linear;
  background: linear-gradient(90deg, #ddd 0%, #ddd calc(50% - 1rem), #e8e8e8 50%, #ddd calc(50% + 1rem), #ddd 100%);
  background-size: 150vw;
  background-position-x: -100vw;
  &.text {
    --color: transparent;
    display: inline;
  }
  * {
    visibility: hidden;
  }
}
@keyframes shine {
  0% {
    background-position: -100vw
  }
  80% {
    background-position: 30vw
  }
  100% {
    background-position: 30vw
  }
}