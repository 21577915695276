#explore {
  width: 100%;
  height: 100svh;
  padding-top: 6.5rem;
  .activepoints {
    bottom: 50%;
    z-index: 10;
  }
  .map {
    flex: 1 auto;
    background-color: rgba(0,0,0,.1);
    .current-location {
      width: 40px;
      height: auto;
      transform: translateY(50%);
    }
    .pin-card {
      width: 45px;
      height: auto;
      opacity: 0;
      transform: translateY(-3rem);
      animation: .15s down-pin cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    }
  }
  .search-card {
    min-width: max-content;
    max-width: 85%;
    bottom: min(3rem, 7.5%);
    &.disabled {
      opacity: .5;
    }
  }
  
  .gm-style {
    .gm-style-iw-c {
      width: 100vw !important;
      max-width: 23rem !important;
      max-height: none !important;
      padding: 1rem !important;
      border-radius: 1.333rem;
    }
    .gm-style-iw-d {
      max-height: none !important;
      overflow: hidden !important;
    }
  }
  .info-window {
    .close-btn {
      width: 3rem;
      height: 3rem;
      position: absolute;
      top: 0;
      right: 0;
      .close-icon {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        cursor: pointer;
        background-color: var(--color-blue);
        &::before, &::after {
          content: '';
          width: 1rem;
          height: 1px;
          margin: auto;
          position: absolute;
          inset: 0;
          transform: rotate(45deg);
          background-color: var(--color-white);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
    .text-location {
      line-height: normal;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-transform: uppercase;
      overflow: hidden;
    }
    .location-image {
      min-height: 10rem;
      max-height: 15rem;
      border-radius: 1rem;
      overflow: hidden;
      background-color: var(--color-blue);
      .full {
        min-height: 10rem;
      }
    }
    .btns {
      padding-top: .5rem;
      padding-bottom: .5rem;
      &:focus-visible {
        transform: scale(1);
        box-shadow: 0px 0px 0px 0px var(--color-black);
      }
      img {
        width: auto;
        height: 2rem;
      }
    }
  }
  + footer {
    display: none;
  }
}

@media (width >= 800px) {
  #explore {
    .gm-style .gm-style-iw-c {
      max-width: 18rem !important;
    }
    + footer {
      display: block;
    }
  }
}

@keyframes down-pin {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
}