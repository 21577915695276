@import './components/styles/fonts';

:root {
  --color-red: #C22634;
  --color-blue: #061E47;
  --color-light-blue: #1D63F5;
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #EAE6F0;
  --shadow: 0px .666rem 2rem 0px rgba(6, 30, 71, 0.15);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: var(--color, current);
  font-family: var(--typography);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

body {
  overflow-x: hidden;
}
html,
body {
  --color: var(--color-blue);
  --typography: 'Bull', 'Helvetica';
  font-size: clamp(12px, 1.25vw, 20px);
  scroll-behavior: smooth;
  background-color: var(--color-white);
}

input, textarea,
select, .btns,
button {
  width: 100%;
  border: none;
  position: relative;
  display: block;
  appearance: none;
  background: none;
  &.disabled, &:disabled {
    pointer-events: none;
  }
}

img, video {
  max-width: 100%;
  display: block;
  &.full {
    object-fit: cover;
  }
  &.col-1 {
    height: auto;
  }
}

.full,
.col-1 {
  width: 100%;
}
.col-2 {
  width: 50%;
}
.col-3 {
  width: 33.333%;
}
.col-4 {
  width: 25%;
}
.col-5 {
  width: 20%;
}
.full {
  height: 100%;
}
.pt-main {
  padding-top: 8rem;
}
.p-6,
.px-6,
.pl-6 {
  padding-left: .5rem;
}
.p-6,
.px-6,
.pr-6 {
  padding-right: .5rem;
}
.p-6,
.py-6,
.pt-6 {
  padding-top: .5rem;
}
.p-6,
.py-6,
.pb-6 {
  padding-bottom: .5rem;
}
.p-12,
.px-12,
.pl-12 {
  padding-left: 1rem;
}
.p-12,
.px-12,
.pr-12 {
  padding-right: 1rem;
}
.p-12,
.py-12,
.pt-12 {
  padding-top: 1rem;
}
.p-12,
.py-12,
.pb-12 {
  padding-bottom: 1rem;
}
.p-18,
.px-18,
.pl-18 {
  padding-left: 1.5rem;
}
.p-18,
.px-18,
.pr-18 {
  padding-right: 1.5rem;
}
.p-18,
.py-18,
.pt-18 {
  padding-top: 1.5rem;
}
.p-18,
.py-18,
.pb-18 {
  padding-bottom: 1.5rem;
}
.p-24,
.px-24,  //40px
.pl-24 {  //40px
  padding-left: 2rem;
}
.p-24,
.px-24,  //40px
.pr-24 {  //40px
  padding-right: 2rem;
}
.p-24,
.py-24,  //40px
.pt-24 {  //40px
  padding-top: 2rem;
}
.p-24,
.py-24,  //40px
.pb-24 {  //40px
  padding-bottom: 2rem;
}
.px-container, .pr-container {
  padding-right: 5%;
}
.px-container, .pl-container {
  padding-left: 5%;
}
////////////////////////////////////////
.mx-auto,
.ml-auto,
[class*="cnt"] {
  margin-left: auto;
}
.mx-auto,
.mr-auto,
[class*="cnt"] {
  margin-right: auto;
}
.my-auto,
.mt-auto,
[class*="cnt"] {
  margin-top: auto;
}
.my-auto,
.mb-auto,
[class*="cnt"] {
  margin-bottom: auto;
}
.m-6,
.mx-6,
.ml-6 {
  margin-left: .5rem;
}
.m-6,
.mx-6,
.mr-6 {
  margin-right: .5rem;
}
.m-6,
.my-6,
.mt-6 {
  margin-top: .5rem;
}
.m-6,
.my-6,
.mb-6 {
  margin-bottom: .5rem;
}
.m-12,
.mx-12,
.ml-12 {
  margin-left: 1rem;
}
.m-12,
.mx-12,
.mr-12 {
  margin-right: 1rem;
}
.m-12,
.my-12,
.mt-12 {
  margin-top: 1rem;
}
.m-12,
.my-12,
.mb-12 {
  margin-bottom: 1rem;
}
.m-18,
.mx-18,
.ml-18 {
  margin-left: 1.5rem;
}
.m-18,
.mx-18,
.mr-18 {
  margin-right: 1.5rem;
}
.m-18,
.my-18,
.mt-18 {
  margin-top: 1.5rem;
}
.m-18,
.my-18,
.mb-18 {
  margin-bottom: 1.5rem;
}
.m-24,
.mx-24,
.ml-24 {
  margin-left: 2rem;
}
.m-24,
.mx-24,
.mr-24 {
  margin-right: 2rem;
}
.m-24,
.my-24,
.mt-24 {
  margin-top: 2rem;
}
.m-24,
.my-24,
.mb-24 {
  margin-bottom: 2rem;
}

.flex,
.btns,
.input-group .input,
.input-group.form-check label {
  display: flex;
  align-items: center;
}
.flex-stretch {
  align-items: stretch;
}
.flex-start {
  align-items: flex-start;
}
.flex-bottom {
  align-items: flex-end;
}
.btns,
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.flex-between {
  justify-content: space-between;
}
.flex-around {
  justify-content: space-around;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.d-none {
  display: none;
}

.btns,
.relative {
  position: relative;
}
[class*="cnt"] {
  position: absolute;
}
.cnt,
.cnt-v {
  top: 0;
  bottom: 0;
}
.cnt,
.cnt-h {
  left: 0;
  right: 0;
}


@media screen and (min-width: 551px) {
  .col-sm-1 {
    width: 100%;
  }
  .col-sm-2 {
    width: 50%;
  }
  .col-sm-3 {
    width: 33.333%;
  }
  .col-sm-4 {
    width: 25%;
  }
  .col-sm-5 {
    width: 20%;
  }
  .flex-sm {
    display: flex;
    align-items: center;
  }
  .flex-sm-stretch {
    align-items: stretch;
  }
  .flex-sm-baseline {
    align-items: flex-start;
  }
  .flex-sm-start {
    align-items: flex-start;
  }
  .flex-sm-bottom {
    align-items: flex-end;
  }
  .flex-sm-center {
    justify-content: center;
  }
  .flex-sm-end {
    justify-content: flex-end;
  }
  .flex-sm-between {
    justify-content: space-between;
  }
  .flex-sm-around {
    justify-content: space-around;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .d-sm-none {
    display: none;
  }
  
}
  
@media screen and (min-width: 769px) {
  .col-md-1 {
    width: 100%;
  }
  .col-md-2 {
    width: 50%;
  }
  .col-md-3 {
    width: 33.333%;
  }
  .col-md-4 {
    width: 25%;
  }
  .col-md-5 {
    width: 20%;
  }
  .p-md-0,
  .px-md-0,
  .pl-md-0 {
    padding-left: 0;
  }
  .p-md-0,
  .px-md-0,
  .pr-md-0 {
    padding-right: 0;
  }
  .p-md-0,
  .py-md-0,
  .pt-md-0 {
    padding-top: 0;
  }
  .p-md-0,
  .py-md-0,
  .pb-md-0 {
    padding-bottom: 0;
  }
  .p-md-6,
  .px-md-6,
  .pl-md-6 {
    padding-left: .5rem;
  }
  .p-md-6,
  .px-md-6,
  .pr-md-6 {
    padding-right: .5rem;
  }
  .p-md-6,
  .py-md-6,
  .pt-md-6 {
    padding-top: .5rem;
  }
  .p-md-6,
  .py-md-6,
  .pb-md-6 {
    padding-bottom: .5rem;
  }
  .p-md-12,
  .px-md-12,
  .pl-md-12{
    padding-left: 1rem;
  }
  .p-md-12,
  .px-md-12,
  .pr-md-12{
    padding-right: 1rem;
  }
  .p-md-12,
  .py-md-12,
  .pt-md-12{
    padding-top: 1rem;
  }
  .p-md-12,
  .py-md-12,
  .pb-md-12{
    padding-bottom: 1rem;
  }
  .p-md-18,
  .px-md-18,
  .pl-md-18 {
    padding-left: 1.5rem;
  }
  .p-md-18,
  .px-md-18,
  .pr-md-18 {
    padding-right: 1.5rem;
  }
  .p-md-18,
  .py-md-18,
  .pt-md-18 {
    padding-top: 1.5rem;
  }
  .p-md-18,
  .py-md-18,
  .pb-md-18 {
    padding-bottom: 1.5rem;
  }
  .p-md-24,
  .px-md-24,
  .pl-md-24 {
    padding-left: 2rem;
  }
  .p-md-24,
  .px-md-24,
  .pr-md-24 {
    padding-right: 2rem;
  }
  .p-md-24,
  .py-md-24,
  .pt-md-24 {
    padding-top: 2rem;
  }
  .p-md-24,
  .py-md-24,
  .pb-md-24 {
    padding-bottom: 2rem;
  }
  ////////////////////////////////////////
  .mx-md-0,
  .ml-md-0 {
    margin-left: 0;
  }
  .mx-md-0,
  .mr-md-0 {
    margin-right: 0;
  }
  .my-md-0,
  .mt-md-0 {
    margin-top: 0;
  }
  .my-md-0,
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mx-md-auto,
  .ml-md-auto {
    margin-left: auto;
  }
  .mx-md-auto,
  .mr-md-auto {
    margin-right: auto;
  }
  .my-md-auto,
  .mt-md-auto {
    margin-top: auto;
  }
  .my-md-auto,
  .mb-md-auto {
    margin-bottom: auto;
  }
  .m-md-6,
  .mx-md-6,
  .ml-md-6 {
    margin-left: .5rem;
  }
  .m-md-6,
  .mx-md-6,
  .mr-md-6 {
    margin-right: .5rem;
  }
  .m-md-6,
  .my-md-6,
  .mt-md-6 {
    margin-top: .5rem;
  }
  .m-md-6,
  .my-md-6,
  .mb-md-6 {
    margin-bottom: .5rem;
  }
  .m-md-12,
  .mx-md-12,
  .ml-md-12{
    margin-left: 1rem;
  }
  .m-md-12,
  .mx-md-12,
  .mr-md-12{
    margin-right: 1rem;
  }
  .m-md-12,
  .my-md-12,
  .mt-md-12{
    margin-top: 1rem;
  }
  .m-md-12,
  .my-md-12,
  .mb-md-12{
    margin-bottom: 1rem;
  }
  .m-md-18,
  .mx-md-18,
  .ml-md-18 {
    margin-left: 1.5rem;
  }
  .m-md-18,
  .mx-md-18,
  .mr-md-18 {
    margin-right: 1.5rem;
  }
  .m-md-18,
  .my-md-18,
  .mt-md-18 {
    margin-top: 1.5rem;
  }
  .m-md-18,
  .my-md-18,
  .mb-md-18 {
    margin-bottom: 1.5rem;
  }
  .m-md-24,
  .mx-md-24,
  .ml-md-24 {
    margin-left: 2rem;
  }
  .m-md-24,
  .mx-md-24,
  .mr-md-24 {
    margin-right: 2rem;
  }
  .m-md-24,
  .my-md-24,
  .mt-md-24 {
    margin-top: 2rem;
  }
  .m-md-24,
  .my-md-24,
  .mb-md-24 {
    margin-bottom: 2rem;
  }
  .flex-md {
    display: flex;
    align-items: center;
  }
  .flex-md-stretch {
    align-items: stretch;
  }
  .flex-md-baseline {
    align-items: baseline;
  }
  .flex-md-start {
    align-items: flex-start;
  }
  .flex-md-bottom {
    align-items: flex-end;
  }
  .flex-md-center {
    justify-content: center;
  }
  .flex-md-end {
    justify-content: flex-end;
  }
  .flex-md-between {
    justify-content: space-between;
  }
  .flex-md-around {
    justify-content: space-around;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .d-md-none {
    display: none;
  }
  .d-md-block {
    display: block;
  }
}
  
@media screen and (min-width: 1001px) {
  .col-lg-1 {
    width: 100%;
  }
  .col-lg-2 {
    width: 50%;
  }
  .col-lg-3 {
    width: 33.333%;
  }
  .col-lg-4 {
    width: 25%;
  }
  .col-lg-5 {
    width: 20%;
  }
/*
  // .p-lg-4,
  // .px-lg-4,
  // .pl-lg-4 {
  //   padding-left: .25rem;
  // }
  // .p-lg-4,
  // .px-lg-4,
  // .pr-lg-4 {
  //   padding-right: .25rem;
  // }
  // .p-lg-4,
  // .py-lg-4,
  // .pt-lg-4 {
  //   padding-top: .25rem;
  // }
  // .p-lg-4,
  // .py-lg-4,
  // .pb-lg-4 {
  //   padding-bottom: .25rem;
  // }
  // .p-lg-8,
  // .px-lg-8,
  // .pl-lg-8 {
  //   padding-left: .5rem;
  // }
  // .p-lg-8,
  // .px-lg-8,
  // .pr-lg-8 {
  //   padding-right: .5rem;
  // }
  // .p-lg-8,
  // .py-lg-8,
  // .pt-lg-8 {
  //   padding-top: .5rem;
  // }
  // .p-lg-8,
  // .py-lg-8,
  // .pb-lg-8 {
  //   padding-bottom: .5rem;
  // }
  // .p-lg-17,
  // .px-lg-17,
  // .pl-lg-17 {
  //   padding-left: 1rem;
  // }
  // .p-lg-17,
  // .px-lg-17,
  // .pr-lg-17 {
  //   padding-right: 1rem;
  // }
  // .p-lg-17,
  // .py-lg-17,
  // .pt-lg-17 {
  //   padding-top: 1rem;
  // }
  // .p-lg-17,
  // .py-lg-17,
  // .pb-lg-17 {
  //   padding-bottom: 1rem;
  // }
  // .p-lg-40,
  // .px-lg-40,  //40px
  // .pl-lg-40 {  //40px
  //   padding-left: 1.333rem;
  // }
  // .p-lg-40,
  // .px-lg-40,  //40px
  // .pr-lg-40 {  //40px
  //   padding-right: 1.333rem;
  // }
  // .p-lg-40,
  // .py-lg-40,  //40px
  // .pt-lg-40 {  //40px
  //   padding-top: 1.333rem;
  // }
  // .p-lg-40,
  // .py-lg-40,  //40px
  // .pb-lg-40 {  //40px
  //   padding-bottom: 1.333rem;
  // }
*/
  ////////////////////////////////////////
  .mx-lg-auto,
  .ml-lg-auto {
    margin-left: auto;
  }
  .mx-lg-auto,
  .mr-lg-auto {
    margin-right: auto;
  }
  .my-lg-auto,
  .mt-lg-auto {
    margin-top: auto;
  }
  .my-lg-auto,
  .mb-lg-auto {
    margin-bottom: auto;
  }
/*
  // .m-lg-4,
  // .mx-lg-4,
  // .ml-lg-4 {
  //   margin-left: .25rem;
  // }
  // .m-lg-4,
  // .mx-lg-4,
  // .mr-lg-4 {
  //   margin-right: .25rem;
  // }
  // .m-lg-4,
  // .my-lg-4,
  // .mt-lg-4 {
  //   margin-top: .25rem;
  // }
  // .m-lg-4,
  // .my-lg-4,
  // .mb-lg-4 {
  //   margin-bottom: .25rem;
  // }
  // .m-lg-8,
  // .mx-lg-8,
  // .ml-lg-8 {
  //   margin-left: .5rem;
  // }
  // .m-lg-8,
  // .mx-lg-8,
  // .mr-lg-8 {
  //   margin-right: .5rem;
  // }
  // .m-lg-8,
  // .my-lg-8,
  // .mt-lg-8 {
  //   margin-top: .5rem;
  // }
  // .m-lg-8,
  // .my-lg-8,
  // .mb-lg-8 {
  //   margin-bottom: .5rem;
  // }
  // .m-lg-17,
  // .mx-lg-17,
  // .ml-lg-17 {
  //   margin-left: 1rem;
  // }
  // .m-lg-17,
  // .mx-lg-17,
  // .mr-lg-17 {
  //   margin-right: 1rem;
  // }
  // .m-lg-17,
  // .my-lg-17,
  // .mt-lg-17 {
  //   margin-top: 1rem;
  // }
  // .m-lg-17,
  // .my-lg-17,
  // .mb-lg-17 {
  //   margin-bottom: 1rem;
  // }
*/
  .flex-lg {
    display: flex;
    align-items: center;
  }
  .flex-lg-stretch {
    align-items: stretch;
  }
  .flex-lg-baseline {
    align-items: flex-start;
  }
  .flex-lg-start {
    align-items: flex-start;
  }
  .flex-lg-bottom {
    align-items: flex-end;
  }
  .flex-lg-center {
    justify-content: center;
  }
  .flex-lg-end {
    justify-content: flex-end;
  }
  .flex-lg-between {
    justify-content: space-between;
  }
  .flex-lg-around {
    justify-content: space-around;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .d-lg-none {
    display: none;
  }
  .d-lg-block {
    display: block;
  }
}
  
@import './components/styles/components';