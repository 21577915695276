#detail-new {
  .back {
    width: 2rem;
    position: absolute;
    top: 6.5rem;
    left: 5%;
    z-index: 1;
  }
  .info-content {
    max-width: 55rem;
  }
  .image::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, .4);
  }

  table, table td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  table td {
    padding: .5rem;
  }

  .no-list {
    list-style: none;
  }


  .small-table {
    th {
      background-color: black;
    }
  }
}