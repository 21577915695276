#mechanics {
  .steps {
    .step {
      img[src*='map'] {
        border-radius: 1.25rem;
        box-shadow: var(--shadow);
      }
    }

    .logo-checo {
      max-width: 16.66rem;
    }

    .number {
      font-size: 10rem;
      line-height: normal;
      transform: translateX(-33.333%);
      background: linear-gradient(90deg, #1D63F6 0%, #113A90 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      +.text-white {
        margin-left: -5.5rem;
      }
    }

    .info {
      font-size: 1.5rem;
      line-height: normal;
    }
  }
}

@media (width >=551px) {
  #mechanics {
    .steps {
      z-index: 1;
      .step {
        padding-left: 5%;

        &:nth-child(2) {
          padding-left: 18%;
        }

        img[src*='map'] {
          max-width: 15rem;
        }
        .video-content {
          max-width: 20rem;
        }
      }

      .number {
        transform: translateX(0);
        font-size: 7rem;

        +.text-white {
          margin-left: 0.5rem;
          max-width: 11rem;
          padding-left: 0;
        }
      }

      .info {
        font-size: 1rem;
      }
    }
  }
}