#not-found {
  .bg {
    bottom: -7.65rem;
    background-color: var(--color-gray);
  }

  .letters {
    img {
      width: 20.125rem;
      margin: -6.25rem 0 -3.5rem;
    }
  }

  .container-not-found {

    .img-blur {
      width: 100%;
      max-width: 27rem;
    }


    .px-container {
      margin-bottom: 6.563rem;

      .text-center {
        max-width: 29rem;
      }

      .btns-container {
        column-gap: 1.25rem;

        a {
          width: 100%;
        }
      }
    }

    .txt-404 {
      font-size: 6.5rem;
      background: linear-gradient(90deg, #1D63F6 0%, #113A90 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      filter: drop-shadow(0px 2px 1px var(--color-white));
    }
  }

}

@media (width >=769px) {
  #not-found .container-not-found {
    margin-top: -5rem;
    .img-blur {
      width: 100%;
      max-width: 50rem;
    }

    .txt-404 {
      font-size: 12.5rem
    }
  }
}